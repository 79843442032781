import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(gsap)
    nuxtApp.provide('gsap', gsap)
    nuxtApp.provide('ScrollTrigger', ScrollTrigger)
    nuxtApp.provide('SplitText', SplitText)
})