<template>
  <footer id="footer" v-bind:class="{'bg-slate-50 text-black':light}">
    <div class="flex flex-col px-4 xl:px-0 lg:flex-row 2xl:max-w-7xl xl:max-w-6xl mx-auto py-24 items-start">
      <div class="col w-full lg:w-1/4  lg:mb-0 lg:mt-0">
        <img loading="lazy" width="150" height="49" v-if="light"  src="~/assets/images/Rollin_black.svg" class="mb-6" style="width: 150px;" alt="Rollin logo" />
        <img loading="lazy" width="150" height="49" v-else  src="~/assets/images/Rollin_white.svg" class="mb-6" style="width: 150px;" alt="Rollin logo">
        <a @click="trackConversion('phone_number')" class="hover:underline py-2 block" href="tel:14182667810"><b>418-266-7810</b></a>
        <a @click="trackConversion('toll_free_number')" class="hover:underline py-2 block" href="tel:18008595850">1-800-859-5850</a>
        <a @click="trackConversion('mailto_email_link')" class="hover:underline py-2 block" :href="`mailto:${$t('general.email')}@rollin.org`">{{ $t('general.email') }}@rollin.org</a><br>
      </div>
      <div v-bind:class="{
        'border-r-[#151b30]': !light,
        'border-r-[#eaeaea]': light
      }" class="col w-full lg:w-1/4 lg:mr-8 lg:pr-8 footer-links lg:border-r">
        <h6 class="text-lg mb-4">Québec</h6>
        <p>
          <b>1020 Rue Bouvier, Suite 400</b> <br/>
          Québec, QC, Canada<br/>
          G2K 0K9
        </p>
        <h6 class="text-lg my-4">Halifax</h6>
        <p>
          <b>1701 Hollis Street, Suite 800</b> <br/>
          Halifax, NS, Canada<br/>
          B3J 3M8
        </p>
        <h6 class="text-lg my-4">New York</h6>
        <p>
          <b>140 Broadway, 46th Floor</b> <br/>
          New York City, NY, USA<br/>
          10005
        </p>
      </div>
      <div class="col w-full lg:w-1/4 lg:mr-4 mt-8 lg:mt-0 footer-links">
        <h6>{{$t('menu.services')}}</h6>
        <nav>
          <ul>
            <li><nuxt-link :to="localePath('/services/design-and-creative')"><span @click="trackEvent('design')">{{$t('menu.design')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/custom-web-development')"><span @click="trackEvent('custom_web_development')">{{$t('menu.customweb')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/drupal-development')"><span @click="trackEvent('drupal_web_development')">{{$t('menu.drupalweb')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/craft-cms-development')"><span @click="trackEvent('craft_web_development')">{{$t('menu.craftweb')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/custom-interfaces')"><span @click="trackEvent('custom_interfaces')">{{$t('menu.interfaces')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/mobile-apps')"><span @click="trackEvent('mobile_apps')">{{$t('menu.mobile')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/automation')"><span @click="trackEvent('automation')">{{$t('menu.automation')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/cloud-infrastructure')"><span @click="trackEvent('infrastructure')">{{$t('menu.cloudhosting')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/vr-experiences')"><span @click="trackEvent('vrar')">{{$t('menu.vrar')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services/email-journeys')"><span @click="trackEvent('email_journeys')">{{$t('menu.emails')}}</span></nuxt-link></li>
          </ul>
        </nav>
      </div>

      <div class="col w-full lg:w-1/4 order-2 lg:order-1 mt-8 lg:mt-0 mr-4 footer-links">
        <h6>{{ $t('menu.about') }}</h6>
        <nav>
          <ul>
            <li><nuxt-link :to="localePath('/')"><span @click="trackEvent('home')">{{$t('menu.home')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/services')"><span @click="trackEvent('services')">{{$t('menu.services')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/case-studies')"><span @click="trackEvent('case_studies')">{{$t('menu.casestudies')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/about-us')"><span @click="trackEvent('about+is')">{{$t('menu.about')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/about-us/why-rollin')"><span @click="trackEvent('why_rollin')">{{$t('menu.whyrollin')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/about-us/careers')"><span @click="trackEvent('careers')">{{$t('menu.careers')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/resources')"><span @click="trackEvent('resources')">{{$t('menu.resources')}}</span></nuxt-link></li>
            <li><nuxt-link :to="localePath('/contact')"><span @click="trackEvent('contact_link')">{{$t('menu.contact')}}</span></nuxt-link></li>
          </ul>
        </nav>
      </div>

    </div>
    <div class="flex items-center text-center w-full px-4 lg:px-auto lg:mx-auto 2xl:max-w-7xl xl:max-w-6xl pb-4 max-w-7xl">
      <small>&copy; {{ year }} - Rollin.org | {{ $t('footer.follow') }}</small>
      <a @click="trackEvent('social_linkedIn')" href="https://linkedin.com/company/rollin-studios" target="_blank" rel="nofollow noopener noreferrer" >
        <img src="~/assets/images/linkedin.svg" width="96" height="24" class="w-24 ml-2" alt="LinkedIn" loading="lazy" />
      </a>

      <div class="hidden lg:flex lg:mx-auto col flex-grow items-center partners justify-end">
        <img loading="lazy" width="80" height="20" src="~/assets/images/drupal.svg" alt="Drupal">
        <img loading="lazy" width="80" height="128" v-if="light" style="margin-top: -50px; opacity: 0.6" src="~/assets/images/designrush-dark.svg" alt="Design Rush">
        <img loading="lazy" width="80" height="128" v-else style="margin-top: -50px" src="~/assets/images/designrush.svg" alt="Design Rush">
        <img loading="lazy" width="80" height="18" src="~/assets/images/Acquia_logo.svg" alt="Acquia">
        <img loading="lazy" v-if="light" src="~/assets/images/platform-dark.svg" alt="Platform.sh Gold partner" style="opacity:0.5;">
        <img loading="lazy" width="80" height="33" v-else src="~/assets/images/platform.svg" alt="Platform.sh Gold partner" style="opacity:0.5;">
        <img loading="lazy" width="80" height="20" src="~/assets/images/salesforce-partner.svg" alt="Salesforce" style="opacity:0.6;">
        <img loading="lazy" width="80" height="20" src="~/assets/images/optimizely-partner.svg" alt="Optimizely" style="opacity:0.4; margin-right: 0;">
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterMain',
  props: ['light'],
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    trackConversion(link){
      const { gtag } = useGtag()
      gtag('event', 'click_contact', {
        'event_category': 'footer',
        'event_label': 'click_'+link
      })
    },
    trackEvent(link){
      const { gtag } = useGtag()
      gtag('event', 'click_footer', {
        'event_category': 'footer',
        'event_label': 'click_'+link
      })
    }
  },
}
</script>
