<template>
  <header class="w-full block absolute">
    <div
        v-bind:class="{'hide-menu':hideMenu}"
        class="pl-4 lg:pl-0 lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto top-0 flex items-center lg:justify-end text-right pt-4">
      <nuxt-link :to="localePath('/')">
        <img width="144" height="48" v-if="dark && !mobileMenuOpen" src="~/assets/images/Rollin_black.svg"
             class="w-36 relative z-30" alt="Rollin Logo"/>
        <img width="144" height="48" v-else src="~/assets/images/Rollin_white.svg" class="w-36 relative z-30"
             alt="Rollin Logo"/>
      </nuxt-link>

      <nav class="hidden grow lg:flex items-center justify-end">
        <nav>
          <ul class="flex justify-end pr-4">
            <li v-if="hideServices" class="pl-12 mx-3 py-8 relative" v-bind:class="{'text-black':dark}">
              <nuxt-link v-bind:class="{'dark':dark}" class="has-children" :to="localePath('/services')">
                <span @click="trackEvent('/services')">{{ $t('menu.services') }}</span>
              </nuxt-link>
            </li>
            <li v-else class="xl:pl-12 mx-3 py-8 relative" v-bind:class="{'text-black':dark}"
                v-on:mouseover="reveal('services',true)" v-on:mouseout="reveal('services',false)">
              <nuxt-link v-bind:class="{'dark':dark}" id="services" class="has-children" :to="localePath('/services')">
                <span @click="trackEvent('/services')">{{ $t('menu.services') }}</span>
              </nuxt-link>
              <div
                  v-bind:class="{ reveal:services }"
                  class="text-black sub-menu drop-shadow-2xl shadow-black p-4 2xl:p-12 rounded-md absolute">
                <div class="max-w-7xl mx-auto flex">
                  <ul class="w-1/3">
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/design-and-creative')"><span
                          class="icon design"></span>
                        <span class="link-content" @click="trackEvent('design')">{{ $t('menu.design') }}<small>{{
                            $t('menutexts.design')
                          }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/custom-web-development')">
                        <span class="icon development"></span>
                        <span class="link-content" @click="trackEvent('custom_web_development')">{{
                            $t('menu.customweb')
                          }}
                        <small>{{ $t('menutexts.customweb') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/drupal-development')"><span
                          class="icon drupal"></span>
                        <span class="link-content" @click="trackEvent('drupal_web_development')">{{
                            $t('menu.drupalweb')
                          }}
                        <small>{{ $t('menutexts.drupalweb') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/craft-cms-development')"><span
                          class="icon craft"></span>
                        <span class="link-content" @click="trackEvent('craft_web_development')">{{
                            $t('menu.craftweb')
                          }}
                        <small>{{ $t('menutexts.craftweb') }}</small></span>
                      </nuxt-link>
                    </li>
                  </ul>
                  <ul class="w-1/3">
                    <li>
                      <nuxt-link :to="localePath('/services/optimizely-cms-development')"><span
                          class="icon optimizely"></span>
                        <span class="link-content" @click="trackEvent('optimizely')">{{ $t('menu.optimizely') }}
                        <small>{{ $t('menutexts.optimizely') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/custom-interfaces')"><span
                          class="icon interface"></span>
                        <span class="link-content" @click="trackEvent('custom_interfaces')">{{ $t('menu.interfaces') }}
                        <small>{{ $t('menutexts.interfaces') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/mobile-apps')"><span
                          class="icon mobile"></span>
                        <span class="link-content" @click="trackEvent('mobile_apps')">{{ $t('menu.mobile') }}
                        <small>{{ $t('menutexts.mobile') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/automation')"><span
                          class="icon automation"></span>
                        <span class="link-content" @click="trackEvent('automation')">{{ $t('menu.automation') }}
                        <small>{{ $t('menutexts.automation') }}</small></span>
                      </nuxt-link>
                    </li>
                  </ul>
                  <ul class="w-1/3">
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/cloud-infrastructure')"><span
                          class="icon cloud"></span>
                        <span class="link-content" @click="trackEvent('cloud_hosting')">{{ $t('menu.cloudhosting') }}
                        <small>{{ $t('menutexts.cloudhosting') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link :to="localePath('/services/artificial-intelligence')"><span class="icon ai"></span>
                        <span class="link-content" @click="trackEvent('ai')">{{ $t('menu.ai') }}
                        <small>{{ $t('menutexts.ai') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/vr-experiences')"><span
                          class="icon vr"></span>
                        <span class="link-content" @click="trackEvent('vrar')">{{ $t('menu.vrar') }}
                          <small>{{ $t('menutexts.vrar') }}</small>
                        </span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/services/email-journeys')"><span
                          class="icon email"></span>
                        <span class="link-content" @click="trackEvent('email_journeys')">{{ $t('menu.emails') }}
                          <small>{{ $t('menutexts.emails') }}</small>
                        </span>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="mx-3 py-8" v-bind:class="{'text-black':dark}">
              <nuxt-link :to="localePath('/case-studies')"><span
                  @click="trackEvent('case_studies')">{{ $t('menu.casestudies') }}</span></nuxt-link>
            </li>
            <li v-if="hideAbout" class="mx-3 py-8 relative" v-bind:class="{'text-black':dark}">
              <nuxt-link v-bind:class="{'dark':dark}" class="has-children" to="/about-us">
                <span @click="trackEvent('about_us')">{{ $t('menu.about') }}</span>
              </nuxt-link>
            </li>
            <li v-else class="mx-3 py-8 relative" v-bind:class="{'text-black':dark}"
                v-on:mouseover="reveal('about',true)" v-on:mouseout="reveal('about',false)">
              <nuxt-link v-bind:class="{'dark':dark}" class="has-children" id="about" :to="localePath('/about-us')">
                <span @click="trackEvent('about_us')">{{ $t('menu.about') }}</span>
              </nuxt-link>
              <div
                  v-bind:class="{ reveal:about }"
                  class="text-black sub-menu2 drop-shadow-2xl shadow-black p-8 rounded-md absolute">
                <div class="max-w-7xl mx-auto flex">
                  <ul class="w-full">
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/about-us/why-rollin')"><span
                          class="icon award"></span>
                        <span class="link-content" @click="trackEvent('why_rollin')">{{ $t('menu.whyrollin') }}<small>{{
                            $t('about-texts.why-rollin')
                          }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/about-us/careers')">
                        <span class="icon career"></span>
                        <span class="link-content" @click="trackEvent('careers')">{{ $t('menu.careers') }}
                        <small>{{ $t('about-texts.careers') }}</small></span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link class="sublink" :to="localePath('/resources')"><span class="icon resource"></span>
                        <span class="link-content" @click="trackEvent('resources')">{{ $t('menu.resources') }}
                        <small>{{ $t('about-texts.resources') }}</small></span>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="mx-3 py-8" v-bind:class="{'text-black':dark}">
              <nuxt-link class="lock" v-bind:class="{'dark':dark}" :to="localePath('/login')">
                <span @click="trackEvent('case_studies')">{{ $t('menu.access') }}</span>
              </nuxt-link>
            </li>
          </ul>
        </nav>
        <ul class="languages border-l-indigo-50 border-l-2 pl-6">
          <li v-bind:class="{'text-black':dark}">
            <nuxt-link v-if="locale === 'en'" :to="switchLocalePath('fr')">Français</nuxt-link>
            <nuxt-link v-else :to="switchLocalePath('en')">English</nuxt-link>
          </li>
        </ul>
        <ul v-if="!hideContact" class="flex justify-end">
          <li class="ml-8">
            <glow-button :to="localePath('/contact')">
              {{ $t('menu.contact') }}
            </glow-button>
          </li>
        </ul>
      </nav>

      <div class="lg:hidden ml-auto">
        <button aria-label="Menu" @click="toggleMenu" v-bind:class="{'lightMode':!dark, 'is-active':mobileMenuOpen}"
                class="hamburger hamburger--slider relative z-30" type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>


      <nav v-bind:class="{'menu-active':mobileMenuOpen}" class="fixed bg-slate-900 inset-0 z-20 mobile-menu">
        <ul class="flex flex-col justify-center items-center h-screen">
          <li class="py-4">
            <nuxt-link :to="localePath('/')"><span @click="trackEvent('mobile_home')">{{ $t('menu.home') }}</span>
            </nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/services')"><span @click="trackEvent('mobile_services')">{{
                $t('menu.services')
              }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/case-studies')"><span
                @click="trackEvent('mobile_case_studies')">{{ $t('menu.casestudies') }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/about-us')"><span @click="trackEvent('mobile_about_us')">{{
                $t('menu.about')
              }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/resources')"><span
                @click="trackEvent('mobile_resources')">{{ $t('menu.resources') }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/contact')"><span @click="trackEvent('mobile_contact')">{{
                $t('menu.contact')
              }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link :to="localePath('/login')"><span @click="trackEvent('client_access')">{{
                $t('menu.access')
              }}</span></nuxt-link>
          </li>
          <li class="py-4">
            <nuxt-link v-if="locale === 'en'" :to="switchLocalePath('fr')">Français</nuxt-link>
            <nuxt-link v-else :to="switchLocalePath('en')">English</nuxt-link>
          </li>
        </ul>
      </nav>

    </div>
  </header>
</template>
<script>
import glowButton from "../../blocks/glow-button/glow-button";


export default {
  data() {
    return {
      services: false,
      about: false,
      hideServices: false,
      hideAbout: false,
      locale: this.$i18n.locale,
      mobileMenuOpen: false,
    }
  },
  setup() {
    const {$gsap} = useNuxtApp()
    return {
      gsap: $gsap
    }
  },
  components: {'glow-button': glowButton},
  props: ['dark', 'hideContact','hideMenu'],
  methods: {
    toggleMenu() {
      let menu = !this.mobileMenuOpen
      const body = document.querySelector('body')
      const html = document.querySelector('html')
      if (menu) {
        body.style.overflow = 'hidden'
        html.style.overflow = 'hidden'
      } else {
        body.style.overflow = 'auto'
        html.style.overflow = 'auto'
      }
      this.mobileMenuOpen = menu

    },
    reveal(module, state) {
      if (module === 'services') {
        this.services = state
      } else {
        this.about = state
      }
    },
    trackEvent(link) {
      const body = document.querySelector('body')
      const html = document.querySelector('html')
      body.style.overflow = 'auto'
      html.style.overflow = 'auto'
      const {gtag} = useGtag()
      gtag('event', 'click_menu', {
        'event_category': 'menu',
        'event_label': 'click_' + link
      })
    }
  },
  mounted() {

    this.gsap.to(".hide-menu",
        {
          opacity: 1,
          duration: 1,
          stagger: 0.2,
          delay: 0.5
        })

    const menuChildrens = document.querySelectorAll('.has-children')
    menuChildrens.forEach((menu) => {
      menu.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          if (e.target.id === 'services') {
            this.services = true
            this.about = false
          }
          if (e.target.id === 'about') {
            this.about = true
            this.services = false
          }
        }
        if (e.key === 'Escape') {
          e.preventDefault()
          this.services = false
          this.about = false
        }
      })
    })

    const subLinks = document.querySelectorAll('.sublink')
    subLinks.forEach((link) => {
      link.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          e.preventDefault()
          this.services = false
          this.about = false
        }
      })
    })

    if (this.$route.path === '/services/' || this.$route.path === '/fr/services/') {
      this.hideServices = true
    }
    if (this.$route.path === '/about-us/' || this.$route.path === '/fr/a-propos/') {
      this.hideAbout = true
    }
    setTimeout(() => {
      this.hideServices = false
      this.hideAbout = false
    }, 2000)
  }
}
</script>
